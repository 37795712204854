<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tên</label>
            </div>
            <div class="col-10">
              <el-input
                placeholder="Tên"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên"></el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Code</label>
            </div>
            <div class="col-10">
              <el-input
                placeholder="Mã"
                v-model="form.code">
              </el-input>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Sử dụng nhiều lần</label>
            </div>
            <div class="col-10">
              <el-switch v-model="form.is_multiple_use"></el-switch>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Miễn phí</label>
            </div>
            <div class="col-10">
              <el-switch v-model="is_free">
              </el-switch>
            </div>
          </div>
          <div class="form-group row" v-if="!is_free">
            <div class="col-2">
              <label class="required">Thể loại</label>
            </div>
            <div class="col-10">
              <el-select
                :class="errors.has('type')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="type"
                data-vv-as="Thể loại"
                class="full-width"
                v-model="form.discount_type">
                <el-option
                v-for="(item, index) in discount_type"
                :key="index"
                :label="item"
                :value="item">
                </el-option>
              </el-select>
              <span class="text-danger" v-if="errors.has('type')">{{ errors.first('type') }}</span>
            </div>
          </div>
          <div class="form-group row" v-if="!is_free && form.discount_type">
            <div class="col-2">
              <label class="required" v-if="form.discount_type == 'value'" >Giảm giá (VND)</label>
              <label class="required" v-if="form.discount_type == 'percent'" >Giảm giá (%)</label>
            </div>
            <div class="col-10">
              <el-input
                type="number"
                placeholder="Giảm giá"
                class="full-width"
                v-model="form.discount"
                :class="errors.has('discount')?'border-danger':''"
                v-validate="'required|min_value:0'"
                data-vv-name="discount"
                data-vv-as="Giá trị">
              </el-input>
              <span class="text-danger" v-if="errors.has('discount')">{{ errors.first('discount') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label class="required">Số lượng</label>
            </div>
            <div class="col-10">
              <el-input
                type="number"
                placeholder="Số lượng"
                v-model="form.amount"
                :class="errors.has('amount')?'border-danger':''"
                v-validate="'required|min_value:1'"
                data-vv-name="amount"
                data-vv-as="Số lượng">
              </el-input>
              <span class="text-danger" v-if="errors.has('amount')">{{ errors.first('amount') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Ghi chú</label>
            </div>
            <div class="col-10">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Ghi chú"
                v-model="form.note">
              </el-input>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Đã sử dụng</label>
            </div>
            <div class="col-10">
              <b>{{form.rest}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group">
            <label>Nguồn</label>
            <el-select class="full-width" v-model="form.type" placeholder="Chọn nguồn coupon">
              <el-option
                v-for="item in sources"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Áp dụng cho users</label>
            <el-select class="full-width" v-model="form.filters" placeholder="Chọn điều kiện lọc" multiple>
              <el-option
                v-for="(item, index) in user_filters"
                :key="index"
                :label="item.label"
                :value="item.key">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Đối tượng áp dụng</label>
            <el-select class="full-width" v-model="form.object" placeholder="Đối tượng áp dụng">
              <el-option
                v-for="item in objects"
                :key="item.value"
                :label="item.title"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group" v-show="form.object === 'race'">
            <label>Chọn sự kiện</label>
            <el-select class="full-width" multiple v-model="form.race_ids" placeholder="Chọn sự kiện">
              <el-option
                v-for="item in races"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="form-group" v-if="form.object === 'addon'">
            <label>Chọn sản phẩm</label>
            <el-select class="full-width" multiple v-model="form.addon_ids" placeholder="Chọn sản phẩm">
              <el-option
                v-for="item in products"
                :key="item.value"
                :label="item.title"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-body form-card">

          <div class="form-group">
            <label class="required">Thời gian bắt đầu</label>
            <el-date-picker
              class="full-width"
              v-model="form.start_at"
              type="datetime"
              range-separator="To"
              start-placeholder="Bắt đầu"
              end-placeholder="Bắt đầu"
              value-format="yyyy-MM-dd HH:mm:ss"
              :class="errors.has('start_at')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="start_at"
              data-vv-as="Ngày bắt dầu">
            </el-date-picker>
            <span class="text-danger" v-if="errors.has('start_at')">{{ errors.first('start_at') }}</span>
          </div>

          <div class="form-group">
            <label class="required">Thời gian kết thúc</label>
            <el-date-picker
              class="full-width"
              v-model="form.end_at"
              type="datetime"
              range-separator="To"
              start-placeholder="Kết thúc"
              end-placeholder="Kết thúc"
              value-format="yyyy-MM-dd HH:mm:ss"
              :class="errors.has('end_at')?'border-danger':''"
              v-validate="'required|earlier'"
              data-vv-name="end_at"
              data-vv-as="Ngày kết thúc">
            </el-date-picker>
            <span class="text-danger" v-if="errors.has('end_at')">{{ errors.first('end_at') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
    <loading :active.sync="loading"></loading>
  </div>
</template>

<script>

import { Select, Option, Input, Button, DatePicker, Switch, Message } from 'element-ui';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    ElOption: Option,
    Message,
    Loading
  },

  beforeCreate() {
    window.AP = this;
    this.$store.dispatch('fetchRacesShort', '?status=0,1');
    this.$store.dispatch('fetchAddons');
  },

  created() {
    let self = this;
    this.$validator.extend('earlier', {
      getMessage(field, val) {
        return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
      },
      validate(value, field) {
        let startParts = self.form.start_at
        let momentStart = new Date(startParts).getTime();
        let momentEnd = new Date(value).getTime();
        return momentEnd > momentStart;
      }
    })
  },

  data() {
    return {
      loading: false,
      form: {
        race_ids: [],
        addon_ids: []
      },
      is_free: true,
      discount_type: ['percent', 'value'],
      sources: ['irace', 'partner'],
      objects: [{
        title: 'Tất cả',
        value: 'all'
      }, {
        title: 'Race',
        value: 'race'
      }, {
        title: 'Tiện ích',
        value: 'addon'
      }],
      user_filters: [
        {
          key: 'completed_profile',
          label: 'User đã hoàn thành profile'
        }
      ]
    }
  },

  computed: {
    originalForm() {
      return this.$store.state.couponDetail;
    },
    races() {
      let data = this.$store.state.shortRaces;
      return data;
    },
    products() {
      let data = this.$store.state.addons;
      let arr = [];
      for (let item of data) {
        arr.push({
          value: item.id,
          title: item.title
        });
      }
      return arr;
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Cập nhật coupon');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

    let id = this.$route.params.id;
    this.$store.dispatch('fetchCouponDetail', id);
  },

  methods: {
    cancel() {
      this.$router.push({name: 'AllCoupons'});
    },

    async save() {
      let self = this;
      this.$validator.validateAll().then(async function (result) {
        if (!result) return;

        self.form.is_free = self.is_free;
        if (self.is_free) self.form.discount = 0;
        self.form.object_ids = self.form.object == 'race' ? self.form.race_ids : self.form.addon_ids;

        self.loading = true;

        await self.$store.dispatch('updateCoupon', self.form).then(res => {
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        }, error => {
          Message({
            dangerouslyUseHTMLString: true,
            message: error,
            type: 'error'
          });
        });

        self.loading = false;

      })
    }
  },
  watch: {
    originalForm(newVal) {
      this.form = Object.assign({}, this.form, newVal);
      if (this.form.object == 'race') {
        this.form.race_ids = this.form.object_ids;
      } else {
        this.form.addon_ids = this.form.object_ids;
      }
    },

    'form.is_free'(newVal, oldValue) {
      this.is_free = newVal == 1 ? true : false;
    },

    'form.is_multiple_use'(newVal, oldValue) {
      this.form.is_multiple_use = newVal == 1 ? true : false;
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.couponDetail = [];
  }
}
</script>
